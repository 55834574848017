<template>
  <FormModal class="form-modal--intro form-modal--secondary">
    <template #body>
      <div class="form-modal__logo-container">
        <InlineSvg
          class="form-modal__logo"
          src="images/logo.svg"
          :alt="concept"
        />
      </div>
      <div class="form-modal__title">
        Ora ti faremo alcune domande. Questo ci aiuterà a trovare gli agenti
        immobiliari giusti per te.
      </div>
    </template>

    <template #footer>
      <t-btn-link
        id="footerNextButton"
        class="footer__next-btn"
        to="/richieste-di-preventivi/tipo-di-progetto"
      >
        Continua
      </t-btn-link>
    </template>
  </FormModal>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'

export default {
  components: {
    FormModal,
    InlineSvg,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Inizia il questionario',
      headDescription:
        'Ora ti faremo alcune domande. Questo ci aiuterà a trovare gli agenti immobiliari giusti per te.',
      path: '/richieste-di-preventivi/comincia',
      progressValue: 0,
      checkoutStep: 1,
    }
  },
}
</script>
